import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './FeedToggleButton.module.css';
import {FeedType} from "../../FeedType";

interface ShareButtonProps {
    feedType: string;
}

const FeedToggleButton: React.FC<ShareButtonProps> = (props) => {
    const navigate = useNavigate();
    const handleToggle = (event: React.MouseEvent<HTMLElement>, newFeedType: string | null) => {
        if (newFeedType !== null) {
            navigate(newFeedType === 'Top' ? '/' : '/fresh');
            window.location.reload();
        }
    };

    return (
        <ToggleButtonGroup
            value={props.feedType}
            exclusive
            onChange={handleToggle}
            aria-label="FeedType"
            className={styles.root}
        >
            <ToggleButton
                value="Top"
                className={`${styles.toggleButton} ${props.feedType === FeedType.Top ? styles.selected : styles.notSelected}`}
            >
                Top
            </ToggleButton>
            <ToggleButton
                value="Fresh"
                className={`${styles.toggleButton} ${props.feedType === FeedType.Fresh ? styles.selected : styles.notSelected}`}
            >
                Fresh
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default FeedToggleButton;
