import React, { FC } from 'react';
import styles from './NewPostButton.module.css';

interface NewPostButtonProps {}

const NewPostButton: FC<NewPostButtonProps> = () => (
    <div className={styles.mainButtonGradiant}>
        <div className={styles.blurBackground}></div>
        <div className={styles.mainButtonBorder}>
            <p className={styles.mainButtonIcon}>+</p>
        </div>
    </div>
);

export default NewPostButton;
