import React, { FC, useState } from 'react';
import { CardMedia, Box } from '@mui/material';

interface ImageProps {
    id: number;
    url: string;
    alt: string;
    isNsfw: boolean;
}

const Image: FC<ImageProps> = (props) => {
    const [isBlurred, setIsBlurred] = useState(props.isNsfw);

    const handleToggleBlur = () => {
        setIsBlurred(!isBlurred);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                cursor: props.isNsfw ? 'pointer' : 'default',
                overflow: 'hidden',
            }}
            onClick={props.isNsfw ? handleToggleBlur : undefined}
        >
            <CardMedia
                component="img"
                image={`${process.env.REACT_APP_API_URL}/medias/${props.id}`}
                alt={props.alt}
                sx={{
                    filter: isBlurred ? 'blur(10px)' : 'none',
                    transition: 'filter 0.3s',
                }}
            />
            {isBlurred && props.isNsfw && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '8px',
                        textAlign: 'center',
                    }}
                >
                    This content is marked NSFW
                </Box>
            )}
        </Box>
    );
};

export default Image;
