import React, { FC, useState, useEffect } from 'react';
import { useAuth, UserButton, useUser } from '@clerk/clerk-react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NewPostButton from '../NewPostButton/NewPostButton';
import { NewPostModal } from '../NewPostModal/NewPostModal';
import CustomSignInButton from '../CustomSignInButton/CustomSignInButton';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#90caf9',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0b0b0',
        },
    },
});

interface LoggedUserDivProps {}

const LoggedUserDiv: FC<LoggedUserDivProps> = () => {
    const { isSignedIn, user } = useUser();
    const { userId } = useAuth();
    const [isModalOpen, setModalOpen] = useState(false);
    const [nickname, setNickname] = useState<string | null>(null);
    const [fullname, setFullname] = useState<string | null>(null);
    const [loadingNickname, setLoadingNickname] = useState(true); // Ajout de l'état de chargement
    const [fontSize, setFontSize] = useState('1rem');

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    useEffect(() => {
        const fetchNickname = async () => {
            if (userId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/infos/${userId}`);
                    setNickname(response.data.nickname);
                    setFullname(response.data.fullName);
                } catch (error) {
                    console.error('Failed to fetch nickname:', error);
                } finally {
                    setLoadingNickname(false);
                }
            }
        };
        fetchNickname();
    }, [userId]);

    useEffect(() => {
        if (user?.fullName) {
            setFullname(user?.fullName)
            if (user.fullName.length > 20) {
                setFontSize('0.8rem');
            } else if (user.fullName.length > 10) {
                setFontSize('0.9rem');
            } else {
                setFontSize('1rem');
            }
        }
    }, [user?.fullName]);

    if (!isSignedIn) {
        return (
            <div>
                <CustomSignInButton />
            </div>
        );
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <Box display="flex" alignItems="center" p={1}>
                <UserButton
                    showName={false}
                    appearance={{
                        elements: {
                            avatarBox: {
                                width: '56px', // Increase avatar size
                                height: '56px',
                            },
                        },
                    }}
                />
                <Box
                    ml={2}
                    display="flex"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                >
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'block' },
                        }}
                    >
                        <Typography variant="h6" component="div" style={{ fontSize }}>
                            {fullname}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {loadingNickname ? 'Creating your name...' : `@${nickname || 'Unknown'}`} {/* Affichage conditionnel */}
                        </Typography>
                    </Box>

                    <div onClick={openModal} style={{ marginLeft: '6px' }}>
                    <span>
                        <NewPostButton />
                    </span>
                    </div>
                    <NewPostModal open={isModalOpen} onClose={closeModal} clerkId={userId?.toString() || ''} />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default LoggedUserDiv;
