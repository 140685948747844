import React, { FC } from 'react';
import {Box, Paper, Typography} from "@mui/material";

interface TermsOfServiceProps {}

const TermsOfService: FC<TermsOfServiceProps> = () => (
    <>
        <Box sx={{ color: 'white', padding: '16px' }}>
            <Paper style={{padding:"14px"}} variant="outlined">
            <Typography variant="h6" gutterBottom>
                Terms of Service
            </Typography>
            <Typography variant="body1" paragraph>
                1. Acceptance of Terms<br />
                By accessing and using our website, you accept and agree to be bound by the terms and provisions of this agreement. Additionally, when using our services, you shall be subject to any posted guidelines or rules applicable to such services.
            </Typography>
            <Typography variant="body1" paragraph>
                2. Provision of Services<br />
                We reserve the right to modify or discontinue, temporarily or permanently, the services provided without notice.
            </Typography>
            <Typography variant="body1" paragraph>
                3. User Responsibilities<br />
                Users agree to use the site in compliance with all applicable local, state, national, and international laws and regulations. Users are prohibited from using the site to harass, abuse, or harm another person, or to engage in any fraudulent activity.
            </Typography>
            <Typography variant="body1" paragraph>
                4. Intellectual Property<br />
                All content included on the site, such as text, graphics, logos, and images, is the property of CursedAI or its content suppliers and protected by international copyright laws. The compilation of all content on this site is the exclusive property of CursedAI.
            </Typography>
            <Typography variant="body1" paragraph>
                5. Limitation of Liability<br />
                The site and its content are provided on an "as is" basis. CursedAI makes no representations or warranties of any kind, express or implied, as to the operation of the site or the information, content, materials, or products included on this site.
            </Typography>
            <Typography variant="body1" paragraph>
                6. Changes to the Terms<br />
                CursedAI reserves the right to change these terms at any time. Any changes will be posted on this page, and users are responsible for reviewing these terms periodically to ensure compliance.
            </Typography>
            <Typography variant="body1" paragraph>
                7. Contact Information<br />
                If you have any questions about these Terms of Service, please contact us at contact@nilauberge.tech.
            </Typography>
            </Paper>
        </Box>
    </>
);

export default TermsOfService;
