import React, { FC, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Typography,
    Divider,
    Box,
    Chip,
    TextareaAutosize
} from '@mui/material';
import { useAuth, UserButton } from "@clerk/clerk-react";
import SendIcon from '@mui/icons-material/Send';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import { getFormattedDate } from "../../utils";

interface CommentSectionProps {
    postId: number;
    isOpen: boolean;
    incrementCommentCount: () => void; // Ajout du callback pour incrémenter le compteur
}

interface Comment {
    id: number;
    content: string;
    createdAt: string;
    user: {
        clerkId: string;
        fullName: string;
        avatarUrl: string;
    };
    replyTo: number | null;
    likeCount: number;
    isLiked: boolean;
}

interface CommentWithChildren extends Comment {
    children?: CommentWithChildren[];
}

const fetchComments = async (postId: number, userId?: string): Promise<Comment[]> => {
    const { data } = await axios.get<Comment[]>(`${process.env.REACT_APP_API_URL}/comments/post/${postId}`);

    const commentsWithLikes = await Promise.all(data.map(async (comment) => {
        if (userId) {
            const response = await axios.get<{ isLiked: boolean }>(`${process.env.REACT_APP_API_URL}/comments/${comment.id}/isLikedBy/${userId}`);
            return { ...comment, isLiked: response.data.isLiked };
        } else {
            return { ...comment, isLiked: false };
        }
    }));

    return organizeComments(commentsWithLikes);
};


const organizeComments = (comments: Comment[]): CommentWithChildren[] => {
    const commentMap: { [key: number]: CommentWithChildren[] } = {};

    comments.forEach(comment => {
        const parentId = comment.replyTo ?? 0;
        const commentWithChildren = { ...comment, children: [] as CommentWithChildren[] };
        if (!commentMap[parentId]) {
            commentMap[parentId] = [];
        }
        commentMap[parentId].push(commentWithChildren);
    });

    const buildTree = (parentId: number): CommentWithChildren[] => {
        return (commentMap[parentId] || []).map(comment => ({
            ...comment,
            children: buildTree(comment.id)
        }));
    };

    return buildTree(0);
};

const CommentSection: FC<CommentSectionProps> = ({ postId, isOpen, incrementCommentCount }) => {
    const [newComment, setNewComment] = useState('');
    const [replyingTo, setReplyingTo] = useState<Comment | null>(null);
    const { userId } = useAuth();
    const [comments, setComments] = useState<CommentWithChildren[]>([]);
    const textFieldRef = useRef<HTMLDivElement>(null);

    const { data, refetch } = useQuery<Comment[]>({
        queryKey: ['comments', postId],
        queryFn: () => fetchComments(postId, userId as string),
        enabled: isOpen,
    });

    useEffect(() => {
        if (data) {
            setComments(data);
        }
    }, [data]);

    useEffect(() => {
        if (isOpen) {
            refetch();
        }
    }, [isOpen, refetch]);

    const handleCommentSubmit = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/comments`, { clerkId: userId, postId, content: newComment, replyTo: replyingTo?.id ?? null });
            setNewComment('');
            setReplyingTo(null);
            incrementCommentCount(); // Incrémentez le compteur de commentaires
            refetch();
        } catch (error) {
            console.error("Error submitting comment:", error);
        }
    };

    const handleLikeComment = async (commentId: number) => {
        try {
            const updatedComments = await updateLikeStatus(comments, commentId);
            setComments(updatedComments);
        } catch (error) {
            console.error(error);
        }
    };

    const updateLikeStatus = async (comments: CommentWithChildren[], commentId: number): Promise<CommentWithChildren[]> => {
        const updatedComments = await Promise.all(comments.map(async (comment) => {
            if (comment.id === commentId) {
                const isLiked = comment.isLiked;
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/comments/like`, { clerkId: userId, commentId });
                return { ...comment, likeCount: response.data.likeCount, isLiked: !isLiked };
            } else if (comment.children) {
                const updatedChildren = await updateLikeStatus(comment.children, commentId);
                return { ...comment, children: updatedChildren };
            }
            return comment;
        }));
        return updatedComments;
    };

    const handleReplyComment = (comment: Comment) => {
        setReplyingTo(comment);
        if (textFieldRef.current) {
            textFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    const handleCancelReply = () => {
        setReplyingTo(null);
        setNewComment('');
    };

    const renderComments = (comments: CommentWithChildren[], level: number = 0): JSX.Element[] => {
        return comments.map(comment => (
            <React.Fragment key={comment.id}>
                <Box display="flex" alignItems="flex-start" style={{ marginLeft: level * 20 }}>
                    {level > 0 && (
                        <Divider orientation="vertical" flexItem sx={{ bgcolor: 'gray', width: '1px', marginRight: '10px', opacity: '60%' }} />
                    )}
                    <ListItem alignItems="flex-start" style={{ flex: 1 }}>
                        <ListItemAvatar>
                            <Avatar alt={comment.user.fullName} src={comment.user.avatarUrl} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <>
                                    <Box display="flex" alignItems="center">
                                        <Typography variant="body1" component="span" sx={{ fontWeight: 'bold', marginRight: '8px' }}>
                                            {comment.user.fullName}
                                        </Typography>
                                        <Typography variant="caption" component="span" sx={{ fontSize: '0.7em', color: 'gray' }}>
                                            {getFormattedDate(comment.createdAt, true)}
                                        </Typography>
                                    </Box>
                                </>
                            }
                            secondary={
                                <span>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary"
                                    >
                                        {comment.content}
                                    </Typography>
                                    <Box display="flex" alignItems="center" mt={1} component="span">
                                        <IconButton size="small" sx={{ marginRight: '4px' }} onClick={() => handleLikeComment(comment.id)}>
                                            {comment.isLiked ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}
                                        </IconButton>
                                        <Typography variant="body2" component="span">
                                            {comment.likeCount}
                                        </Typography>
                                        <IconButton size="small" sx={{ marginLeft: '8px' }} onClick={() => handleReplyComment(comment)}>
                                            <ReplyIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                </span>
                            }
                        />
                    </ListItem>
                </Box>
                {comment.children && renderComments(comment.children, level + 1)}
            </React.Fragment>
        ));
    };

    return (
        <div>
            <Box ref={textFieldRef} display="flex" flexDirection="column" mb={2} sx={{ backgroundColor: '#1c1c1c', padding: '10px', borderRadius: '8px' }}>
                {replyingTo && (
                    <Box display="flex" alignItems="center" mb={2}>
                        <Chip
                            label={`@${replyingTo.user.fullName}`}
                            sx={{ backgroundColor: 'gray', color: 'white', marginRight: '10px' }}
                            onDelete={handleCancelReply}
                            deleteIcon={<CloseIcon />}
                            title={'Your random attribued name'}
                        />
                        <Typography variant="body2" component="span" sx={{ color: 'gray', fontSize: 'small' }}>
                            Replying to {replyingTo.user.fullName}
                        </Typography>
                    </Box>
                )}
                <Box display="flex" alignItems="center">
                    <UserButton
                        showName={false}
                        appearance={{
                            elements: {
                                avatarBox: {
                                    width: '36px', // Increase avatar size
                                    height: '36px',
                                },
                            },
                        }}
                    />
                    <TextareaAutosize
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '8px',
                            flexGrow: 1,
                            resize: 'none',
                            backgroundColor: '#2c2c2c',
                            color: '#fff',
                            fontSize: '16px',
                            fontFamily: 'Roboto, sans-serif'
                        }}
                        minRows={2}
                        placeholder="Write a comment"
                    />
                    <IconButton color="primary" onClick={handleCommentSubmit} disabled={userId == null}>
                        <SendIcon />
                    </IconButton>
                </Box>
            </Box>

            <List>
                {renderComments(comments)}
            </List>
        </div>
    );
};

export default CommentSection;
