import React, {FC, useEffect, useState} from 'react';
import axios from 'axios';
import {Avatar, Box, Typography, Container, CircularProgress, Paper, Divider} from '@mui/material';
import {getFormattedDate} from "../../utils";

interface SearchResultHeaderProps {
    category?: string;
    tag?: string;
    query?: string;
    user?: string;
}

interface ProfileInfoProps {
    clerkId: string;
}

interface ProfileInterface {
    fullName: string;
    nickname: string;
    avatarUrl: string;
    createdAt: string; // Date d'inscription ajoutée ici
}

const SearchResultHeader: FC<SearchResultHeaderProps> = (props) => (
    <Container>
        {props.query && <Typography variant="h4">Search results for: {props.query}</Typography>}
        {props.tag && <Typography variant="h4">#{props.tag}</Typography>}
        {props.user && <ProfileInfos clerkId={props.user}/>}
        {props.category && (
            <Typography variant="h4">
                Category {props.category.charAt(0).toUpperCase() + props.category.slice(1)}
            </Typography>
        )}
    </Container>
);

const ProfileInfos: FC<ProfileInfoProps> = ({clerkId}) => {
    const [profile, setProfile] = useState<ProfileInterface | null>(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get<ProfileInterface>(`${process.env.REACT_APP_API_URL}/user/infos/${clerkId}`);
                setProfile(response.data);
            } catch (error) {
                console.error('Failed to fetch profile:', error);
            }
        };
        fetchProfile();
    }, [clerkId]);

    if (!profile) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Box display="flex" alignItems="center" mt={2} mb={2}>
                <Avatar alt={profile.nickname} src={profile.avatarUrl} sx={{width: 64, height: 64, mr: 2}}/>
                <Box>
                    <Typography variant="h6" fontWeight="bold">{profile.fullName}</Typography>
                    <Typography variant="body2" color="textSecondary">@{profile.nickname}</Typography>
                    <Typography variant="body2" color="textSecondary">Member since {getFormattedDate(profile.createdAt, true)}</Typography>
                </Box>
            </Box>
            <Divider variant={"middle"} style={{ margin: '17px' }} />
        </>
    );
};

export default SearchResultHeader;
