import React, { FC, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';

interface VideoProps {
    url: string | null;
    id: number;
    isNsfw: boolean;
}

const Video: FC<VideoProps> = ({ url, id, isNsfw }) => {
    const [videoUrl, setVideoUrl] = useState<string>(url || '');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBlurred, setIsBlurred] = useState(isNsfw);

    useEffect(() => {
        const fetchVideo = async () => {
            if (videoUrl === '') {
                setIsLoading(true);
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/medias/${id}`);
                    if (response.data && response.data.url) {
                        setVideoUrl(response.data.url);
                    } else {
                        console.error('Invalid response from the server', response);
                    }
                } catch (error) {
                    console.error('Error fetching video:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchVideo();
    }, [videoUrl, id]);

    const handleToggleBlur = () => {
        setIsBlurred(!isBlurred);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                cursor: isNsfw ? 'pointer' : 'default',
                overflow: 'hidden',
                paddingBottom: '56.25%', // 16:9 aspect ratio
                height: 0,
            }}
            onClick={isNsfw ? handleToggleBlur : undefined}
        >
            {isLoading ? (
                <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
            ) : (
                <ReactPlayer
                    url={videoUrl}
                    width="100%"
                    height="100%"
                    style={{
                        filter: isBlurred ? 'blur(10px)' : 'none',
                        transition: 'filter 0.3s',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                    controls
                    playing
                    muted
                    playsinline
                    loop
                />
            )}
            {isBlurred && isNsfw && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '8px',
                        textAlign: 'center',
                    }}
                >
                    This content is marked NSFW
                </Box>
            )}
        </Box>
    );
};

export default Video;
