import React from 'react';
import { SignInButton } from '@clerk/clerk-react';
import { Button } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
const CustomSignInButton: React.FC = () => {

    return (
        <SignInButton mode="modal" fallbackRedirectUrl="/logged" signUpFallbackRedirectUrl="/logged" >
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<LoginIcon/>}
                    sx={{
                        backgroundColor: '#4caf50',
                        '&:hover': {
                            backgroundColor: '#388e3c',
                        }
                    }}
                >
                    Sign In
                </Button>
        </SignInButton>
    );
};

export default CustomSignInButton;
