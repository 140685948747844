import React, {FC} from 'react';
import {Box, createTheme, Paper, Typography} from "@mui/material";
import TermsOfService from "../TermsOfService/TermsOfService";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Header from "../Header/Header";
import {ThemeProvider} from "@mui/material/styles";
import Footer from "../Footer/Footer";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const About: FC = () => (

    <ThemeProvider theme={darkTheme}>
        <Header/>
        <Box sx={{color: 'white', padding: '16px'}}>
            <Paper style={{padding: "14px"}} variant="outlined">
                <Typography variant='h6' gutterBottom>
                    Welcome to Cursed-AI
                </Typography>
                <Typography variant="body1" paragraph>
                    This platform is created with a non-profit purpose in mind. We strive to provide resources and
                    content to
                    our users without any commercial intent.
                    <br/><br/>
                    <b>The only goal and desire is to have fun together.</b>
                </Typography>
            </Paper>
        </Box>
        <TermsOfService/>
        <PrivacyPolicy/>
        <Footer/>

    </ThemeProvider>
);

export default About;
