import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: '#1e1e1e',
                color: 'white',
                py: 2,
                mt: 3,
                textAlign: 'center',
                zIndex:1000
            }}
        >
            {/*<img src="/cursed-ai-mix-horizontal.png"  alt="Logo" style={{width: '50px', height: '50px'}} /> /!* Ajoutez cette ligne pour afficher le logo *!/*/}
            <Typography variant="body2" component="p">
                &copy; {new Date().getFullYear()} Cursed.AI
            </Typography>
            <Typography variant="body2" component="p">
                <Link href="/" color="inherit" underline="hover">Home</Link> | <Link href="/about" color="inherit" underline="hover">About</Link> | <Link href="mailto:contact@nilauberge.tech" color="inherit" underline="hover">Contact</Link>
            </Typography>
        </Box>
    );
};

export default Footer;
