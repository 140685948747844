import React from 'react';
import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {ThemeProvider} from "@mui/material/styles";
import {createTheme, CssBaseline, List, Paper, Typography} from "@mui/material";
import styles from './CategoriesPanel.module.css';
import CategoryButton from "../CategoryButton/CategoryButton";

const fetchCategories = async (): Promise<string[]> => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
    return response.data;
};


const CategoriesPanel: React.FC = () => {
    const {data, isLoading, error} = useQuery({queryKey: ['categories', 'all'], queryFn: fetchCategories});

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });


    if (isLoading) return <>Loading...</>;
    if (error instanceof Error) return <>Error: {error.message}</>;
    if (!data) return <>No categories found.</>;

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <div className={styles.CategoriesPanel}>
                <Paper style={{padding:"12px"}}>
                    <Typography fontWeight={"lighter"}>Categories</Typography>
                    <List>
                        {data.map((category, index) => (
                            <CategoryButton category={category} key={index}/>
                        ))}
                    </List>
                </Paper>
            </div>
        </ThemeProvider>
    );
}

export default CategoriesPanel;
