import React, { useState } from 'react';
import {
    TextField,
    IconButton,
    Typography,
    Paper,
    createTheme,
    ThemeProvider, CssBaseline
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tag from "../Tag/Tag";
import styles from "../CategoryPicker/CategoryPicker.module.css";

interface TagsInputProps {
    tags: string[];
    setTags: (newTags: string[]) => void;
}

const TagsInput: React.FC<TagsInputProps> = ({ tags, setTags }) => {
    const [input, setInput] = useState('');
    const [error, setError] = useState('');

    const handleAddTag = () => {
        if (input.length > 16) {
            setError('Tag must be 16 characters or less');
            return;
        }
        if (input && !tags.includes(input)) {  // Prevent duplicates
            setTags([...tags, input]);
            setInput('');
            setError('');
        }
    };

    const handleDeleteTag = (tagToDelete: string) => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleAddTag();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Remove spaces from the input
        const noSpaces = e.target.value.replace(/\s/g, '');
        setInput(noSpaces);
        setError(''); // Clear error on input change
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className={styles.TagsInput}>
                <Paper style={{ padding: "12px" }}>
                    <Typography fontWeight={"lighter"}>Add tags</Typography>
                    <div style={{ marginTop: '8px' }}>
                        <TextField
                            value={input}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            variant="outlined"
                            size="small"
                            placeholder="Add tag"
                            sx={{ flex: 1, minWidth: '150px' }}
                            error={!!error}
                            helperText={error}
                        />
                        <IconButton onClick={handleAddTag} aria-label="add tag" disabled={tags.length >= 5}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                        <div style={{ marginTop: '8px' }}>
                            {tags.map((tag, index) => (
                                <span onClick={() => handleDeleteTag(tag)} style={{ cursor: 'pointer' }} key={index + 'span'}>
                                    <span style={{ pointerEvents: 'none' }} key={index + 'span2'}>
                                        <Tag key={index} tag={tag} />
                                    </span>
                                </span>
                            ))}
                        </div>
                    </div>
                </Paper>
            </div>
        </ThemeProvider>
    );
};

export default TagsInput;
