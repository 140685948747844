import React, {FC} from 'react';
import {Button} from "@mui/material";
import styles from './Tag.module.css';
import {decode} from "html-entities";

interface TagProps {
    tag: string;
}

const Tag: FC<TagProps> = (props) => (
    <Button className={styles.TagButton}
            variant="contained"
            href={`/tag/${props.tag}`}>
        {decode(props.tag)}
    </Button>

);

export default Tag;
