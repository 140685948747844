import React, { useState } from 'react';
import styles from './ReportPopup.module.css';

interface ReportPopupProps {
    onClose: () => void;
    onSubmit: (reportData: { post_id: number; reason: string; comment: string }) => void;
    postId: number;
}

const ReportPopup: React.FC<ReportPopupProps> = ({ onClose, onSubmit, postId }) => {
    const [reason, setReason] = useState('');
    const [comment, setComment] = useState('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit({ post_id: postId, reason, comment });
    };

    return (
        <div className={styles.popup}>
            <div className={styles.popupInner}>
                <button className={styles.closeButton} onClick={onClose}>&times;</button>
                <center><h3>Tell us why this post should be reported</h3></center>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Reason:</label>
                        <select value={reason} onChange={(e) => setReason(e.target.value)} required>
                            <option value="">Select a reason</option>
                            <option value="Spam">Spam</option>
                            <option value="Copyright Violation">Copyright Violation</option>
                            <option value="Violence">Violence</option>
                            <option value="Inappropriate Content">Inappropriate Content</option>
                            <option value="Fraud">Fraud</option>
                            <option value="OTHER">Other</option>
                        </select>
                    </div>
                    <div>
                        <label>Comment:</label>
                        <textarea value={comment} onChange={(e) => setComment(e.target.value)} maxLength={512} />
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default ReportPopup;
