import React from 'react';
import { useAppContext } from '../../AppContext';

const RateLimitExceeded: React.FC = () => {
    const { rateLimitError, setRateLimitError } = useAppContext();

    const handleClose = () => {
        setRateLimitError(null);
        localStorage.removeItem('rateLimitError');
    };

    if (!rateLimitError) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 10000,
        }}>
            <div style={{
                textAlign: 'center',
                backgroundColor: '#19af02',
                padding: '20px',
                borderRadius: '5px',
                color: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
            }}>
                <p style={{ margin: 0, fontWeight: 'bold' }}>{rateLimitError.message}</p>
                <p style={{ margin: '5px 0' }}>Retry after: {rateLimitError.retryAfter} seconds</p>
                <button
                    onClick={handleClose}
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        border: 'none',
                        borderRadius: '3px',
                        cursor: 'pointer',
                        padding: '5px 10px',
                        marginTop: '10px'
                    }}
                >
                    Retry
                </button>
            </div>
        </div>
    );
};

export default RateLimitExceeded;
