import React, {FC} from 'react';
import {Box, Paper, Typography} from "@mui/material";

const PrivacyPolicy: FC = () => (

    <Box sx={{color: 'white', padding: '16px'}}>
        <Paper style={{padding: "14px"}} variant="outlined">
            <Typography variant="h6" gutterBottom>
                Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
                At CursedAI, we respect your privacy and are committed to protecting it. This Privacy Policy explains
                how we collect, use, and share your personal information when you use our website.
            </Typography>
            <Typography variant="body1" paragraph>
                1. Information We Collect<br/>
                We may collect personal information that you voluntarily provide to us when you register on our site,
                subscribe to our newsletter, or otherwise interact with our services.
            </Typography>
            <Typography variant="body1" paragraph>
                2. Use of Information<br/>
                The information we collect is used to improve our website and services, to personalize your experience,
                and to communicate with you.
            </Typography>
            <Typography variant="body1" paragraph>
                3. Sharing of Information<br/>
                We do not sell, trade, or otherwise transfer your personal information to outside parties except as
                required by law or to trusted partners who assist us in operating our website.
            </Typography>
            <Typography variant="body1" paragraph>
                4. Security<br/>
                We implement a variety of security measures to maintain the safety of your personal information.
            </Typography>
            <Typography variant="body1" paragraph>
                5. Changes to This Policy<br/>
                We may update this Privacy Policy from time to time. Any changes will be posted on this page.
            </Typography>
        </Paper>
    </Box>
);

export default PrivacyPolicy;
