import React, {FC} from 'react';
import styles from './CategoryButton.module.css';
import {Button} from "@mui/material";

interface CategoryButtonProps {
    category: string;
}

const CategoryButton: FC<CategoryButtonProps> = (props) => (
    <Button
        className={styles.CategoryButton}
        variant="contained"
        href={`/category/${props.category.toLowerCase()}`}>
        {props.category}
    </Button>
);

export default CategoryButton;
