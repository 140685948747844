import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

import styles from './SearchBar.module.css';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    marginLeft: 0,
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
    display: 'flex', // Assurer l'alignement des enfants
    alignItems: 'center',
}), styles.SearchBar);

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    right: 0,
    cursor: 'pointer',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'black',
    paddingRight: `calc(1em + ${theme.spacing(2)})`, // Ajout du padding pour l'icône
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(1)})`,
        transition: theme.transitions.create('width'),
        width: '40%',
        maxWidth: '13ch', // Limiter la largeur de l'input
        [theme.breakpoints.up('sm')]: {
            width: '10ch', // Taille initiale de l'input
            '&:focus': {
                width: '15ch', // Taille de l'input en focus
            },
        },
        [theme.breakpoints.down('sm')]: {
            width: '15ch', // Taille initiale de l'input sur petits écrans
            '&:focus': {
                width: '20ch', // Taille de l'input en focus sur petits écrans
            },
        },
    },
    '& .MuiInputBase-input::placeholder': {
        color: 'black',
        fontWeight: 'bold',
    },
}));

export default function SearchBar() {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const handleSearch = () => {
        if (searchQuery.trim() !== '') {
            navigate(`/posts/search?query=${encodeURIComponent(searchQuery)}`);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Search>
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <SearchIconWrapper onClick={handleSearch}>
                    <SearchIcon />
                </SearchIconWrapper>
            </Search>
        </Box>
    );
}
