import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from './RecentSearch.module.css';

interface RecentSearchCardProps {
    count: string;
    tag: string;
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#90caf9',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0b0b0',
        },
    },
});

const RecentSearchCard = (props: RecentSearchCardProps) => {
    return (
        <a className={`${styles.RecentSearch} ${styles.RecentSearchCard}`} href={"/posts/search?query="+props.tag}>
            <Card className={styles.searchCard}
                  sx={{ display: 'flex', alignItems: 'center', mb: 1, height: 40, bgcolor: 'background.paper', color: 'text.primary' }}>
                <Box sx={{ p: 1, color: 'text.secondary' }}>
                    <SearchIcon sx={{verticalAlign:'middle'}}/>
                </Box>
                <CardContent sx={{ flex: '1 0 auto', p: 1, marginTop:'15px', maxWidth:'210px' }}>
                    <Typography component="div" variant="subtitle2">
                        <span className={styles.recentSearchContent}><span style={{fontWeight: 'bold'}}>{props.tag}</span> ({props.count} results)</span>
                    </Typography>

                </CardContent>
            </Card>
        </a>
    );
};

const RecentSearches = () => {
    const [searches, setSearches] = useState<RecentSearchCardProps[]>([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/tendencyTag`)
            .then(response => {
                setSearches(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the tendency tags!", error);
            });
    }, []);

    return (
        <ThemeProvider theme={darkTheme}>
            <div className={styles.RecentSearch}>
                <Typography variant="subtitle1" component="div" style={{alignItems: 'center'}}>
                    Recent searches
                </Typography>
                {searches.map((search, index) => (
                    <RecentSearchCard key={index} tag={search.tag} count={search.count} />
                ))}
            </div>
        </ThemeProvider>
    );
};

export default RecentSearches;
