import React, { FC, useEffect, useState } from 'react';
import { Transmit } from "@adonisjs/transmit-client";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme, CssBaseline, Fade, List, ListItemText, Paper, Typography } from "@mui/material";
import styles from "./NotificationsPanel.module.css";

interface NotificationsPanelProps {}

type Activity = {
    eventName: string;
    eventDescription: string;
    timestamp: string;
    postId: number;
    commentId: number;
};

const EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour

const NotificationsPanel: FC<NotificationsPanelProps> = () => {
    const [messages, setMessages] = useState<Activity[]>([]);
    const [transmit, setTransmit] = useState<Transmit | null>(null);

    useEffect(() => {
        const transmitInstance = new Transmit({
            baseUrl: `${process.env.REACT_APP_API_URL}`,
        });

        setTransmit(transmitInstance);

        return () => {
            if (transmitInstance) {
                transmitInstance.close();
            }
        };
    }, []);

    useEffect(() => {
        if (transmit) {
            transmit.listenOn<Activity>('activityStream', (payload) => {
                setMessages(prevMessages => {
                    const newMessages = [payload, ...prevMessages].slice(0, 5); // Ajoute les nouveaux messages en haut et limite à 5
                    const dataWithExpiration = {
                        messages: newMessages,
                        expiration: new Date().getTime() + EXPIRATION_TIME
                    };
                    localStorage.setItem('messages', JSON.stringify(dataWithExpiration));
                    return newMessages;
                });
            });
        }
    }, [transmit]);

    useEffect(() => {
        const storedData = localStorage.getItem('messages');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const currentTime = new Date().getTime();
            if (currentTime < parsedData.expiration) {
                setMessages(parsedData.messages.slice(-5)); // Charge les 5 derniers messages
            } else {
                localStorage.removeItem('messages'); // Remove expired data
            }
        }
    }, []);

    const calculateOpacity = (index: number, total: number) => {
        return 1 - (index / (total + 1));
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const getEventText = (eventDescription: string) => {
        if (eventDescription.includes("this post")) {
            return "this post";
        } else if (eventDescription.includes("check it out!")) {
            return "check it out!";
        }
        return null;
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className={styles.NotificationsPanel}>
                <Paper style={{ padding: "12px" }}>
                    <Typography fontWeight={"lighter"}>Live activity</Typography>
                    <List>
                        {messages.length === 0 && (
                            <ListItemText>
                                <Typography variant={"caption"}> It's quiet here... </Typography>
                            </ListItemText>
                        )}
                        {messages.map((message, index) => {
                            const date = new Date(message.timestamp);
                            const hours = date.getHours().toString().padStart(2, '0');
                            const minutes = date.getMinutes().toString().padStart(2, '0');
                            const time = `${hours}:${minutes}`;

                            const postId = message.postId;

                            const eventText = getEventText(message.eventDescription);
                            const parts = eventText ? message.eventDescription.split(eventText) : [message.eventDescription];

                            return (
                                <Fade in={true} timeout={1000} key={index}>
                                    <ListItemText key={index} style={{ opacity: calculateOpacity(index, messages.length) }}>
                                        <Typography variant={"caption"}> {time} - </Typography>
                                        {eventText ? (
                                            <>
                                                {parts[0]}
                                                <a href={`/post/${postId}`} style={{ color: "#d000df", fontWeight: 'bold' }}>{eventText}</a>
                                                {parts[1]}
                                            </>
                                        ) : (
                                            <>{message.eventDescription}</>
                                        )}
                                    </ListItemText>
                                </Fade>
                            );
                        })}
                    </List>
                </Paper>
            </div>
        </ThemeProvider>
    );
};

export default NotificationsPanel;
