import React from 'react';
import styles from './DislikeButton.module.css';
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import { ThumbDown } from "@mui/icons-material";
import { IconButton } from "@mui/material";

interface DislikeButtonProps {
    isDisliked: boolean;
    onClick: () => void;
    count: number;
    disabled: boolean;
}

const DislikeButton: React.FC<DislikeButtonProps> = ({ isDisliked, onClick, count, disabled }) => (
    <div className={styles.iconContainer}>
        <IconButton aria-label={"dislike this post"} onClick={onClick} disabled={disabled} className={styles.iconButton}>
            {isDisliked ? <ThumbDown className={styles.icon} /> : <ThumbDownAltOutlinedIcon className={styles.icon} />}
            <span className={styles.buttonCount}>{count}</span>
        </IconButton>
    </div>
);

export default DislikeButton;
