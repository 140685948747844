export const getInitials = (fullName: string) => {
    const names = fullName.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getFormattedDate = (dateString: string, short: boolean = false) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffMs = now.getTime() - date.getTime();
    const diffSecs = diffMs / 1000;
    const diffMins = diffSecs / 60;
    const diffHours = diffMins / 60;
    const diffDays = diffHours / 24;

    if (short) {
        if (diffDays > 2) {
            return date.toLocaleString('en-EN', {
                year: 'numeric', month: 'long', day: 'numeric'
            });
        } else if (diffDays > 1) {
            return "Yesterday";
        } else if (diffHours > 1) {
            return `${Math.floor(diffHours)}h`;
        } else if (diffMins > 1) {
            return `${Math.floor(diffMins)}min`;
        } else {
            return "now";
        }
    } else {
        if (diffDays > 2) {
            return date.toLocaleString('en-EN', {
                year: 'numeric', month: 'long', day: 'numeric'
            });
        } else if (diffDays > 1) {
            return "Posted yesterday";
        } else if (diffHours > 1) {
            return `Posted ${Math.floor(diffHours)} hours ago`;
        } else if (diffMins > 1) {
            return `Posted ${Math.floor(diffMins)} minutes ago`;
        } else {
            return "Posted just now";
        }
    }
};