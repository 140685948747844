import React from 'react';
import { useQuery } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme, CssBaseline, List, Paper, Typography } from "@mui/material";
import styles from './TagsPanel.module.css';
import Tag from "../Tag/Tag";
import useAxios from "../../axiosConfig";

const fetchTags = async (axiosInstance: ReturnType<typeof useAxios>): Promise<string[]> => {
    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/tags`);
    return response.data;
};

const TagsPanel: React.FC = () => {
    const axiosInstance = useAxios();

    const { data, isLoading, error } = useQuery({
        queryKey: ['tags', 'all'],
        queryFn: () => fetchTags(axiosInstance)
    });

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    if (isLoading) return <>Loading...</>;
    if (error instanceof Error) return <>Error: {error.message}</>;
    if (!data) return <>No tag found.</>;

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className={styles.TagsPanel}>
                <Paper style={{ padding: "12px" }}>
                    <Typography fontWeight={"lighter"}>Popular Tags</Typography>
                    <List>
                        {data.map((tag, index) => (
                            <Tag key={index} tag={tag} />
                        ))}
                    </List>
                </Paper>
            </div>
        </ThemeProvider>
    );
}

export default TagsPanel;
