import React from 'react';
import './App.css';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import axios from "axios";
import Layout from "./components/Layout/Layout";
import {AppProvider} from "./AppContext";
import {CookieConsent} from "react-cookie-consent";
import CookieIcon from '@mui/icons-material/Cookie';

const queryClient = new QueryClient();
axios.defaults.withCredentials = true;

function App(props: { feedType: any; }) {

    return (
        <QueryClientProvider client={queryClient}>
            <AppProvider>
                <Layout feedType={props.feedType}/>
                <CookieConsent> <CookieIcon style={{verticalAlign: 'middle'}} /> We use cookies to manage authentication and ensure a smooth login experience. By continuing to use our site, you agree to our use of cookies.</CookieConsent>
            </AppProvider>
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    );
}

export default App;
