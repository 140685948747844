import React, { FC, useEffect, useState } from 'react';
import styles from './CommentButton.module.css';
import { IconButton } from "@mui/material";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import axios from "axios";

interface CommentButtonProps {
    postId: number;
    onClick: () => void;
    incrementCommentCount: () => void;
}

const CommentButton: FC<CommentButtonProps> = ({ postId, onClick, incrementCommentCount }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const fetchCommentCount = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/comments/post/${postId}/count`);
                setCount(response.data.count);
            } catch (error) {
                console.error("Error fetching comment count:", error);
            }
        };

        fetchCommentCount();
    }, [postId, incrementCommentCount]);

    const handleClick = () => {
        onClick();
        incrementCommentCount();
    }

    return (
        <div className={styles.iconContainer}>
            <IconButton aria-label={"comment this post"} onClick={handleClick} className={styles.iconButton}>
                <ChatBubbleOutlineIcon className={styles.icon} />
                <span className={styles.buttonCount}>{count}</span>
            </IconButton>
        </div>
    );
}

export default CommentButton;
