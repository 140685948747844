import React, {FC, useMemo} from 'react';
import styles from './MainContent.module.css';
import PostsArea from "../PostsArea/PostsArea";
import {useParams} from "react-router-dom";
import SearchResultHeader from "../SearchResultHeader/SearchResultHeader";
import {FeedType} from "../../FeedType";
import {createTheme, ThemeProvider} from '@mui/material';

interface MainContentProps {
    feedType: FeedType;
}

const MainContent: FC<MainContentProps> = (props) => {
    const params = useParams();

    const searchResultHeader = useMemo(() => {
        if (props.feedType === FeedType.Category) {
            return <SearchResultHeader category={params.category}/>;
        } else if (props.feedType === FeedType.Tag) {
            return <SearchResultHeader tag={params.tag}/>;
        } else if (props.feedType === FeedType.SearchResult) {
            return <SearchResultHeader query={params.search}/>;
        } else if (props.feedType === FeedType.User) {
            return <SearchResultHeader user={params.userId}/>;
        }
        return null;
    }, [props.feedType, params]);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <div className={styles.MainContent}>
                {searchResultHeader}
                <PostsArea feedType={props.feedType}/>
            </div>
        </ThemeProvider>
    );
}

export default MainContent;