import React, {FC} from 'react';
import {Box, createTheme, ThemeProvider} from "@mui/material";
import {Link} from "react-router-dom";

interface HeaderProps {
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography: {
        fontFamily: '"Archivo", sans-serif',
    },
});

const Header: FC<HeaderProps> = () => (

    <ThemeProvider theme={darkTheme}>
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <Link to="/" style={{display: 'flex', justifyContent: 'center'}}>
                <img src="/cursed-ai-logo.png" alt="logo"
                     style={{maxWidth: '10%', height: 'auto'}}/>
            </Link>
        </Box>

    </ThemeProvider>
);
export default Header;
