import React from 'react';
import { Button } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

class LoadMoreButton extends React.Component<{ loadMorePosts: any }> {
    render() {
        let {loadMorePosts} = this.props;
        return (
            <div style={{textAlign: 'center', margin: '40px 0'}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={loadMorePosts}
                    startIcon={<ArrowDownwardIcon/>}
                    sx={{
                        backgroundColor: '#4caf50',
                        '&:hover': {
                            backgroundColor: '#388e3c',
                        }
                    }}
                >
                    Load More Posts
                </Button>
            </div>
        );
    }
}

export default LoadMoreButton;
