import React, { useEffect, useState } from 'react';
import styles from './CategoryPicker.module.css';
import { useQuery } from "@tanstack/react-query";
import { Button, createTheme, CssBaseline, List, Paper, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import useAxios from "../../axiosConfig";

interface CategoryPickerProps {
    updateCategory: (category: string) => void;
}

const fetchCategories = async (axiosInstance: ReturnType<typeof useAxios>): Promise<string[]> => {
    const response = await axiosInstance.get('/categories');
    return response.data;
};

const CategoryPicker: React.FC<CategoryPickerProps> = ({ updateCategory }) => {
    const axiosInstance = useAxios();
    const { data, isLoading, error } = useQuery({
        queryKey: ['categories', 'all'],
        queryFn: () => fetchCategories(axiosInstance)
    });
    const [activeButton, setActiveButton] = useState<string | null>(null);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    useEffect(() => {
        if (data && data.length > 0) {
            const defaultCategory = data[0];
            updateCategory(defaultCategory);
            setActiveButton(defaultCategory);
        }
    }, [data]);

    if (isLoading) return <>Loading...</>;
    if (error instanceof Error) return <>Error: {error.message}</>;
    if (!data) return <>No categories found.</>;

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div className={styles.CategoriesPanel}>
                <Paper style={{ padding: "12px" }}>
                    <Typography fontWeight={"lighter"}>Choose a category *</Typography>
                    <List>
                        {data.map((category, index) => (
                            <Button
                                key={index}
                                className={`${styles.CategoryButton} ${activeButton === category ? styles.Selected : ''}`}
                                onClick={() => {
                                    updateCategory(category);
                                    setActiveButton(category);
                                }}
                                variant="contained">
                                {category}
                            </Button>
                        ))}
                    </List>
                </Paper>
            </div>
        </ThemeProvider>
    );
}

export default CategoryPicker;
