import axios, { AxiosError, AxiosInstance } from 'axios';
import { useAppContext } from './AppContext';

const useAxios = (): AxiosInstance => {
    const { setRateLimitError } = useAppContext();

    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    instance.interceptors.response.use(
        response => response,
        (error: AxiosError) => {
            if (error.response && error.response.status === 429) {
                const data = error.response.data as { errors: { message: string; retryAfter: number }[] };
                const retryAfter = data.errors?.[0]?.retryAfter || 60;
                setRateLimitError({
                    message: 'Too many requests',
                    retryAfter,
                });
            }
            return Promise.reject(error);
        }
    );

    return instance;
};

export default useAxios;
