import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    Box,
    TextField,
    CircularProgress,
    Snackbar,
    Alert,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    FormControlLabel,
    Checkbox,
    Tooltip,
    Divider,
    Grid, Typography,
} from '@mui/material';
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { pink } from "@mui/material/colors";
import CategoryPicker from "../CategoryPicker/CategoryPicker";
import MenuItem from "@mui/material/MenuItem";
import TagsInput from "../TagsInput/TagsInput";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import { UploadFile } from "@mui/icons-material";
import { useDropzone } from 'react-dropzone';

interface Props {
    open: boolean;
    onClose: () => void;
    clerkId: string;
}

interface FormData {
    title: string;
    prompts: string;
    usedEngine: string;
    category: string;
    tags: string[];
    clerkId: string;
    file?: File;
    isNSFW: boolean;
    // description: string;
}

const engineOptions = [
    { label: 'DALL-E 3', value: 'DALL-E 3' },
    { label: 'Stable Diffusion', value: 'Stable Diffusion' },
    { label: 'Midjourney', value: 'Midjourney' },
    { label: 'Imagen', value: 'Imagen' },
    { label: 'Artbreeder', value: 'Artbreeder' },
    { label: 'Sora', value: 'Sora' },
    { label: 'Kapwing', value: 'Kapwing' },
    { label: 'Other', value: 'Other' }
];

export const NewPostModal: React.FC<Props> = ({ open, onClose, clerkId }) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileType, setFileType] = useState<'image' | 'video' | null>(null);
    const [currentStep, setCurrentStep] = useState('upload');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [titleError, setTitleError] = useState(false);
    const [loadedImageUrl, setLoadedImageUrl] = useState('');

    const initialState = {
        title: "",
        prompts: "",
        usedEngine: "DALL-E 3",
        category: "Funny",
        // description: "",
        tags: [],
        clerkId: clerkId,
        isNSFW: false
    }
    const [formData, setFormData] = useState<FormData>(initialState);
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            if (fileUrl) {
                URL.revokeObjectURL(fileUrl);
            }
        };
    }, [fileUrl]);

    useEffect(() => {
        const image = new Image();
        image.src = '/cursed-ai-logo.png';
        image.onload = () => {
            setLoadedImageUrl(image.src);
        };
    }, []);

    const mutation = useMutation<Response, Error, FormData>({
        mutationFn: (formData: FormData) => {
            const formDataObject = new FormData();
            if (formData.file) {
                formDataObject.append('file', formData.file);
            }
            formDataObject.append('title', formData.title);
            formDataObject.append('prompts', formData.prompts);
            formData.tags.forEach(tag => formDataObject.append('tags[]', tag));
            formDataObject.append('category', formData.category);
            formDataObject.append('clerkId', formData.clerkId);
            formDataObject.append('usedEngine', formData.usedEngine);
            formDataObject.append('isNSFW', formData.isNSFW.toString());

            return fetch(`${process.env.REACT_APP_API_URL}/posts`, {
                method: 'POST',
                body: formDataObject,
            });
        },
        onSuccess: async (response) => {
            if (!response.ok) {
                const errorData = await response.json();
                setSnackbarMessage('Upload failed please try again in a while');
                console.log(errorData.message)
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } else {
                const data = await response.json();
                setSnackbarMessage('File successfully posted');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setCurrentStep('upload');
                setFile(null);
                setFileUrl(null);
                setFormData(initialState);

                onClose();
                navigate(`/post/${data.id}`, { replace: true });
            }
        },
        onError: (error) => {
            setSnackbarMessage('Upload failed: ' + error.message);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));

        if (name === 'title') {
            setTitleError(false);
        }
    };

    const handleSelectChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (files: File[]) => {
        const maxImageSize = parseInt(process.env.REACT_APP_IMAGE_MAX_SIZE || '5000000'); // Default to 5MB
        const maxVideoSize = parseInt(process.env.REACT_APP_VIDEO_MAX_SIZE || '50000000'); // Default to 50MB

        if (files && files[0]) {
            const selectedFile = files[0];
            const fileType = selectedFile.type.startsWith('video/') ? 'video' : 'image';
            const maxSize = fileType === 'video' ? maxVideoSize : maxImageSize;

            if (selectedFile.size > maxSize) {
                setSnackbarMessage(`The file size exceeds the maximum allowed size for ${fileType}s.`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                return;
            }

            setFile(selectedFile);
            const fileUrl = URL.createObjectURL(selectedFile);
            setFileUrl(fileUrl);
            setFileType(fileType);
            setCurrentStep('details');
        }
    };

    const handleBackToUpload = () => {
        setCurrentStep('upload');
        setFile(null);
        setFileUrl(null);
        setFileType(null);
        setFormData(initialState);
    };

    const handleFinalSubmit = () => {
        if (!formData.title) {
            setTitleError(true);
            return;
        }

        if (file && formData) {
            const finalFormData: FormData = {
                ...formData,
                file: file
            };
            mutation.mutate(finalFormData);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const renderDetailsForm = () => {
        if (currentStep !== 'details' || !fileUrl) return null;

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {fileType === 'image' && (
                    <img src={fileUrl} alt="Uploaded Preview"
                         style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }} />
                )}
                {fileType === 'video' && (
                    <video controls
                           style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }}>
                        <source src={fileUrl} type={file?.type} />
                        Your browser does not support the video tag.
                    </video>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            error={titleError}
                            helperText={titleError ? "Title is required" : ""}
                            label="Title"
                            name="title"
                            required={true}
                            value={formData.title}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            margin="normal"
                            variant="outlined"
                            autoFocus={true}
                            inputProps={{ maxLength: 100 }}

                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="used-engine-label">Engine *</InputLabel>
                            <Select
                                labelId="used-engine-label"
                                id="used-engine-select"
                                name="usedEngine"
                                required={true}
                                value={formData.usedEngine}
                                label="Used Engine"
                                onChange={handleSelectChange}
                                size="small"
                            >
                                {engineOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Prompt given for generation"
                            name="prompts"
                            value={formData.prompts}
                            onChange={handleInputChange}
                            fullWidth
                            multiline
                            rows={2}
                            margin="normal"
                            variant="outlined"
                            inputProps={{ maxLength: 500 }}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={formData.isNSFW} onChange={handleInputChange} name="isNSFW"
                                               color="primary" />}
                            label="This content is NSFW"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CategoryPicker updateCategory={(category: string) => setFormData({ ...formData, category })} />
                        <TagsInput tags={formData.tags}
                                   setTags={(newTags: string[] | string) => {
                                       let tagsArray: string[];
                                       if (Array.isArray(newTags)) {
                                           tagsArray = newTags;
                                       } else {
                                           tagsArray = [newTags];
                                       }
                                       setFormData({ ...formData, tags: tagsArray })
                                   }}
                        />
                    </Grid>
                </Grid>
                <Alert variant="outlined" severity="info">
                    Please try to respect the spirit by only posting AI-generated content, and selecting the right category.
                </Alert>
                <div style={{ margin: 'auto' }}>
                    <Button onClick={handleBackToUpload} variant="contained" color="primary"
                            startIcon={<ArrowBackIcon />} sx={{
                        backgroundColor: '#4caf50',
                        '&:hover': {
                            backgroundColor: '#388e3c',
                        },
                        mt: 2,
                        m: 2
                    }}>
                        Back
                    </Button>
                    <Button onClick={handleFinalSubmit} variant="contained" color="primary" startIcon={<DoneIcon />}
                            sx={{
                                backgroundColor: '#4caf50',
                                '&:hover': {
                                    backgroundColor: '#388e3c',
                                },
                                mt: 2,
                                m: 2
                            }}>
                        {mutation.isPending ? <CircularProgress size={24} /> : "Publish"}
                    </Button>
                </div>
            </Box>
        );
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': [],
            'video/*': []
        },
        onDrop: handleFileChange
    });

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description">
            <React.Fragment>
                <Box
                    sx={{
                        maxHeight: '80vh',
                        overflow: 'auto',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {
                            xs: '90%', // 90% width on extra small screens
                            sm: '80%', // 80% width on small screens
                            md: '50%'  // 50% width on medium screens and up
                        },
                        bgcolor: 'background.paper',
                        border: `2px solid ${pink[500]}`,
                        p: 4,
                        borderRadius: 2,
                        boxShadow: 3
                    }}
                >
                    {currentStep === 'upload' && (
                        <>
                            <img src={loadedImageUrl} alt="logo"
                                 style={{ maxWidth: '100%', height: 'auto', marginBottom: 2 }} />
                            <br /><br /><br />
                            <div {...getRootProps()} style={{ border: '2px dashed #4caf50', padding: '20px', textAlign: 'center' }}>
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the file here ...</p>
                                ) : (
                                    <Button
                                        variant="contained"
                                        component="span"
                                        color="primary"
                                        startIcon={<UploadFile />}
                                        sx={{
                                            mb: 2,
                                            backgroundColor: '#4caf50',
                                            '&:hover': {
                                                backgroundColor: '#388e3c',
                                            }
                                        }}
                                        fullWidth>
                                        {mutation.isPending ? <CircularProgress size={24} /> : "Upload file"}
                                    </Button>
                                )}
                                <Typography variant="caption" component="span"
                                            sx={{fontSize: '0.7em', color: 'gray'}}>
                                    or drop it here</Typography>
                            </div>

                            <br />
                            <Divider variant={"middle"} style={{ marginTop: '7px' }} />
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '6px'
                            }}>
                                <span>OR</span>
                            </div>
                            <Divider variant={"middle"} />
                            <br />
                            <Tooltip title={'Coming soon...'}>
                                <Button
                                    variant="contained"
                                    component="span"
                                    color="secondary"
                                    fullWidth
                                    sx={{
                                        mb: 2,
                                        backgroundColor: '#4caf50',
                                        '&:hover': {
                                            backgroundColor: '#388e3c',
                                        }
                                    }}
                                >
                                    Cursed.AI generator
                                </Button>
                            </Tooltip>
                        </>
                    )}
                    {renderDetailsForm()}
                </Box>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </React.Fragment>
        </Modal>
    );
}
