import React, { FC, useState } from 'react';
import styles from './Layout.module.css';
import CategoriesPanel from '../CategoriesPanel/CategoriesPanel';
import NotificationsPanel from '../NotificationsPanel/NotificationsPanel';
import Grid from '@mui/material/Grid';
import { Box, createTheme, Divider, Hidden, IconButton, SwipeableDrawer, Typography } from '@mui/material';
import MainContent from '../MainContent/MainContent';
import { FeedType } from '../../FeedType';
import TagsPanel from '../TagsPanel/TagsPanel';
import LoggedUserDiv from '../LoggedUserDiv/LoggedUserDiv';
import FeedToggleButton from '../FeedToggleButton/FeedToggleButton';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';
import RateLimitExceeded from '../RateLimitExceeded/RateLimitExceeded';
import { useAppContext } from '../../AppContext';
import SearchBar from "../searchBar/SearchBar";
import RecentSearches from "../RecentSearch/RecentSearch";
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider } from "@mui/material/styles";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#90caf9',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0b0b0',
        },
    },
});

interface LayoutProps {
    feedType: FeedType;
}

const Layout: FC<LayoutProps> = ({ feedType }) => {
    const { rateLimitError } = useAppContext();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        rateLimitError ? <RateLimitExceeded /> :
            <div className={styles.Layout}>
                {/*<Header />*/}
                <Box className={styles.LayoutMain}>
                    <Hidden smUp>
                        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                            <img src={"/cursed-ai-logo-mobile.png"} alt="logo" style={{ maxWidth: '80%', height: 'auto' }} />
                        </Box>
                    </Hidden>
                    <Grid container spacing={1} sx={{ mt: 1 }}>
                        <Hidden mdDown>
                            <Grid item md={2}>{/* Colonne vide à gauche */}</Grid>
                        </Hidden>
                        <Hidden mdDown>
                            <Grid item xs={0} sm={2} md={2}>
                                <div className={'leftColumn'} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    maxWidth: '100%',
                                    position: 'sticky',
                                    top: '20px'
                                }}>
                                    <Link to="/" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src="/cursed-ai-logo-gradient.png" alt="logo"
                                             style={{ maxWidth: '70%', height: 'auto' }} />
                                    </Link>
                                    <Typography variant="subtitle1" component="div"
                                                style={{ alignItems: 'center' }}>
                                        When AI goes wrong
                                    </Typography>
                                    <br />
                                    <FeedToggleButton feedType={feedType} />
                                    <br />
                                    <Divider color="gray" style={{ height: 1, width: '60%', margin: '12px' }} />
                                    <br />
                                    <SearchBar />
                                    <br />
                                    <RecentSearches />
                                    <br />
                                    <Divider color="gray" style={{ height: 1, width: '60%', margin: '12px' }} />
                                    <LoggedUserDiv />
                                </div>
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={9} md={4}>
                            <Box sx={{
                                overflowY: 'auto',
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': { display: 'none' }
                            }}>
                                <MainContent feedType={feedType} />
                            </Box>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={0} sm={1} md={2}>
                                <div style={{
                                    position: 'fixed',
                                    top: 0,
                                    bottom: 0,
                                    width: '100%',
                                    display: 'grid',
                                    gridTemplateRows: 'auto auto 1fr',
                                    height: '92vh',
                                    gap: '10px',
                                    boxSizing: 'border-box',
                                }}>
                                    <div>
                                        <CategoriesPanel />
                                    </div>
                                    <div>
                                        <TagsPanel />
                                    </div>
                                    <div style={{ overflow: 'auto' }}>
                                        <NotificationsPanel />
                                    </div>
                                </div>
                            </Grid>
                        </Hidden>



                        <Hidden mdDown>
                            <Grid item md={2}>{/* Colonne vide à droite */}</Grid>
                        </Hidden>
                    </Grid>
                </Box>
                <Footer />
                <Hidden smUp>
                    <IconButton onClick={toggleDrawer(true)} sx={{ position: 'fixed', top: 10, left: 10, color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '50%' }}>
                        <MenuIcon />
                    </IconButton>
                    <SwipeableDrawer
                        anchor="left"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        PaperProps={{
                            style: {
                                backgroundColor: darkTheme.palette.background.default,
                                color: darkTheme.palette.text.primary,
                            }
                        }}
                    >
                        <ThemeProvider theme={darkTheme}>
                            <Box
                                sx={{ width: 250 }}
                                role="presentation"
                                onClick={toggleDrawer(false)}
                                onKeyDown={toggleDrawer(false)}
                            >
                                <div className={'leftColumn'} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    maxWidth: '100%',
                                    top: '80px'
                                }}>
                                    <Link to="/" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src="/cursed-ai-logo-gradient.png" alt="logo"
                                             style={{ maxWidth: '70%', height: 'auto' }} />
                                    </Link>
                                    <Typography variant="subtitle1" component="div"
                                                style={{ alignItems: 'center' }}>
                                        When AI goes wrong
                                    </Typography>
                                    <br />
                                    <FeedToggleButton feedType={feedType} />
                                    <br />
                                    <Divider color="gray" style={{ height: 1, width: '60%', margin: '12px' }} />
                                    <br />
                                    <SearchBar />
                                    <br />
                                    <RecentSearches />
                                    <br />
                                    <Divider color="gray" style={{ height: 1, width: '60%', margin: '12px' }} />
                                    <LoggedUserDiv />
                                </div>
                            </Box>
                        </ThemeProvider>
                    </SwipeableDrawer>
                </Hidden>
            </div>
    );
};

export default Layout;
