import React, { FC, useState, useEffect, useCallback } from 'react';
import styles from './PostsArea.module.css';
import axios from "axios";
import {Params, useLocation, useParams} from "react-router-dom";
import { FeedType } from "../../FeedType";
import PostCard from "../PostCard/PostCard";
import LoadMoreButton from "../LoadMoreButton/LoadMoreButton";
import {useAuth, useUser} from "@clerk/clerk-react";

interface PostsAreaProps {
    feedType: FeedType;
}

const PostsArea: FC<PostsAreaProps> = ({ feedType }) => {
    const params = useParams();
    const [postIds, setPostIds] = useState<number[]>([]);
    const [page, setPage] = useState(0);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const fetchPosts = async (feedType: string, params: Readonly<Params>, page: number): Promise<number[]> => {
        const limit = 10;
        const offset = page * limit;
        let response;

        if (feedType === FeedType.Top || feedType === FeedType.Fresh) {
            response = await axios.post(`${process.env.REACT_APP_API_URL}/posts/${feedType}`, {limit: 10, offset: offset});
        } else if (feedType === FeedType.Tag) {
            response = await axios.post(`${process.env.REACT_APP_API_URL}/posts/top`, {limit: 10, tag: params.tag, offset: offset });
        } else if (feedType === FeedType.User) {
            response = await axios.get(`${process.env.REACT_APP_API_URL}/posts/user/${params.userId}`);
            setPostIds([]);
        } else if (feedType === FeedType.Post) {
            response = { data: [Number(params.id)] };
            setPostIds([Number(params.id)]);
            return response.data;
        } else if (feedType === FeedType.SearchResult) {
            const queryParams = new URLSearchParams(location.search);
            const query = queryParams.get('query') || '';

            response = await axios.get(`${process.env.REACT_APP_API_URL}/posts/search?query=${encodeURIComponent(query)}`);
            setPostIds([]);
        } else {
            response = await axios.post(`${process.env.REACT_APP_API_URL}/posts/top`, {limit: 10, category: params.category, offset: offset});
        }

        return response.data;
    }

    useEffect(() => {
        const loadPosts = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const newPosts = await fetchPosts(feedType, params, page);
                setPostIds(prev => {
                    const uniqueNewPosts = newPosts.filter(postId => !prev.includes(postId));
                    return [...prev, ...uniqueNewPosts];
                });
            } catch (err) {
                if (err instanceof Error) {
                    setError(err);
                }
            } finally {
                setIsLoading(false);
            }
        };

        loadPosts();
    }, [feedType, params, page]);

    const loadMorePosts = useCallback(() => {
        setPage(prevPage => prevPage + 1);
    }, []);

    if (error) return <>Error: {error.message}</>;
    if (postIds.length === 0 && isLoading) return <>Loading...</>;

    return (
        <div className={styles.PostsArea}>
            {postIds.map(postId => (
                <div key={postId}>
                    <PostCard id={postId} unique={postIds.length==1}/>
                    <br style={{ marginTop: '20px', marginBottom: '20px' }} />
                </div>
            ))}
            {feedType !== FeedType.Post && feedType !== FeedType.User && <LoadMoreButton loadMorePosts={loadMorePosts} />}
        </div>
    );
}

export default PostsArea;
