import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    createTheme,
    CssBaseline,
    Divider,
    IconButton,
    IconButtonProps,
    styled,
    Typography
} from "@mui/material";
import {red} from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {getFormattedDate, getInitials} from "../../utils";
import {Link} from "react-router-dom";
import Tag from "../Tag/Tag";
import {useQuery} from '@tanstack/react-query';
import Image from "../Image/Image";
import Video from "../Video/Video";  // Import Video component
import {ThemeProvider} from '@mui/material/styles';
import CategoryButton from "../CategoryButton/CategoryButton";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useUser} from "@clerk/clerk-react";
import {useAuth} from "@clerk/clerk-react";
import ShareButtonWithDialog from "../ShareButtonWithDialog/ShareButtonWithDialog";
import LikeButton from "../LikeButton/LikeButton";
import DislikeButton from "../DislikeButton/DislikeButton";
import {decode} from "html-entities";
import CommentButton from "../CommentButton/CommentButton";
import CommentSection from '../CommentSection/CommentSection';
import ReportPopup from "../ReportPopup/ReportPopup";
import useAxios from '../../axiosConfig'; // Importez le hook useAxios
import {AxiosError} from 'axios';
import {useClerk} from '@clerk/clerk-react';
import {Helmet} from 'react-helmet';

interface PostCardProps {
    id: number;
    unique: boolean;
}

interface Post {
    id: number;
    title: string;
    description: string;
    usedEngine: string;
    prompts: string;
    userId: number;
    tags: string[];
    category: string;
    createdAt: string;
    updatedAt: string;
    media: {
        id: number;
        url: string;
        alt: string;
        isNsfw: boolean;
        type: 'image' | 'video';
    },
    user: {
        fullName: string;
        email: string;
        avatarUrl: string;
        clerkUserId: string;
    },
    commentsCount: number;
    likesCount: number;
    dislikesCount: number;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const fetchPost = async (axiosInstance: ReturnType<typeof useAxios>, postId: number): Promise<Post | null> => {
    try {
        const {data} = await axiosInstance.get(`/posts/${postId}`);
        if (data.tags !== null) {
            data.tags = data.tags.replace(/[{}"]/g, '').split(',');
        }
        return data;
    } catch (error: unknown) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 404) {
            console.error('Post not found:', axiosError.response.data);
            return null; // or throw a custom error if you prefer
        } else {
            console.error('An error occurred while fetching the post:', axiosError);
            throw axiosError; // rethrow other types of errors
        }
    }
};

const fetchUserInfo = async (axiosInstance: ReturnType<typeof useAxios>, clerkUserId: string) => {
    const {data} = await axiosInstance.get(`/user/infos/${clerkUserId}`);
    return data;
};

const PostCard: React.FC<PostCardProps> = ({id, unique}) => {
    const axiosInstance = useAxios();
    const {isSignedIn, isLoaded} = useUser();
    const {userId} = useAuth();
    const [expanded, setExpanded] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const {data: post, isLoading, error} = useQuery({
        queryKey: ['post', id],
        queryFn: () => fetchPost(axiosInstance, id)
    });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [likeStatus, setLikeStatus] = useState<{ isLiked: boolean, isDisliked: boolean }>({
        isLiked: false,
        isDisliked: false
    });
    const [likeCount, setLikeCount] = useState<number>(0);
    const [dislikeCount, setDislikeCount] = useState<number>(0);
    const [commentSectionOpen, setCommentSectionOpen] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState(false);
    const {openSignIn} = useClerk();

    useEffect(() => {
        if (!isLoaded) return;

        if (post) {
            setLikeCount(Number(post.likesCount));
            setDislikeCount(Number(post.dislikesCount));
        }

        if (!isSignedIn) return;

        const fetchLikeStatus = async () => {
            const {data} = await axiosInstance.get(`/posts/${id}/isLikedBy/${userId}`);
            setLikeStatus(data);
        };

        fetchLikeStatus();
    }, [id, post, isLoaded, isSignedIn, userId]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        if (userId) {
            // Check if user can delete post
            const userInfo = await fetchUserInfo(axiosInstance, userId as string);
            if (userInfo.userType !== 'user') {
                setCanDelete(true);
            }
        }

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleReportClick = () => {
        setShowPopup(true);
        setAnchorEl(null);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    const handleSubmit = async (reportData: { post_id: number; reason: string; comment: string }) => {
        try {
            const response = await axiosInstance.post(`/posts/report`, {
                ...reportData,
                clerkId: userId
            });

            if (response.status === 201) {
                alert('Report submitted successfully');
                handlePopupClose();
            } else {
                alert('Failed to submit report :(');
            }
        } catch (error) {
            console.error('Error submitting report:', error);
            alert('An error occurred while submitting the report');
        }
    };

    const handleLike = async () => {
        if (!userId) {
            openSignIn();
            return;
        }

        try {
            const response = await axiosInstance.post(`/posts/${id}/like`, {userId});
            const isLiked = response.data.message === 'Post liked';
            setLikeStatus(prevStatus => ({...prevStatus, isLiked: isLiked}));
            setLikeCount(response.data.likeCount);
        } catch (error) {
            // Do nothing, for now...
        }
    };

    const handleDislike = async () => {
        if (!userId) {
            openSignIn();
            return;
        }

        try {
            const response = await axiosInstance.post(`/posts/${id}/dislike`, {userId});
            const isDisliked = response.data.message === 'Post disliked';
            setLikeStatus(prevStatus => ({...prevStatus, isDisliked: isDisliked}));
            setDislikeCount(response.data.dislikeCount);
        } catch (error) {
            // Do nothing, for now...
        }
    };

    const handleDeleteClick = async () => {
        try {
            await axiosInstance.delete(`/posts/${id}`);
            // You may want to add a callback here to refresh the list of posts or navigate away
            alert('Post deleted successfully');
            window.location.reload()
        } catch (error) {
            alert('Failed to delete post');
        }
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const incrementCommentCount = () => {
        if (post) {
            post.commentsCount += 1;
        }
    };

    if (isLoading || !isLoaded) return <>Loading...</>;
    if (error instanceof Error) return <>Error: {error.message}</>;
    if (!post) return <>No post found.</>;

    const handleCommentClick = () => {
        setCommentSectionOpen(!commentSectionOpen);
    }

    return (
        <>
            {unique &&
            <Helmet>
                <meta property="og:title" content={post.title}/>
                <meta property="og:description" content={post.description || "Check out this post on Cursed.AI"}/>
                <meta property="og:image" content={post.media.url}/>
                <meta property="og:url" content={`https://cursed-ai.com/post/${post.id}`}/>
                <meta property="og:type" content="article"/>
                <title>{post.title}</title>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@BestOfCursedAi"/>
                <meta name="twitter:title" content={post.title}/>
                <meta name="twitter:image" content={post.media.url}/>
            </Helmet>
            }
            <ThemeProvider theme={darkTheme}>
                <CssBaseline/>
                <div>
                    <Card sx={{maxWidth: 900}}>
                        <CardHeader
                            avatar={
                                <Link to={`/user/profile/${post.user.clerkUserId}`}>
                                    <Avatar sx={{bgcolor: red[500]}} aria-label="user">
                                        {post.user.avatarUrl ?
                                            <div style={{
                                                borderRadius: "50%",
                                                overflow: "hidden",
                                                width: "100%",
                                                height: "100%"
                                            }}>
                                                <img style={{
                                                    objectFit: 'cover',
                                                    width: "100%",
                                                    height: "100%"
                                                }} alt="avatar" src={post.user.avatarUrl}/>
                                            </div>
                                            :
                                            getInitials(post.user.fullName)
                                        }
                                    </Avatar>
                                </Link>
                            }
                            action={
                                <div>
                                    <IconButton aria-label="settings" onClick={handleClick}>
                                        <MoreVertIcon/>
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleReportClick}>Report</MenuItem>
                                        <MenuItem onClick={handleDeleteClick} disabled={!canDelete}>Delete</MenuItem>
                                    </Menu>
                                </div>
                            }
                            title={post.user.fullName}
                            subheader={getFormattedDate(post.createdAt)}
                        />
                        {post.media.type === 'image' ? (
                            <Image url={post.media.url} alt={post.media.alt} id={post.media.id}
                                   isNsfw={post.media.isNsfw}/>
                        ) : (
                            <Video url={post.media.url} isNsfw={post.media.isNsfw} id={post.media.id}/>
                        )}
                        <CardActions disableSpacing>
                            <Typography gutterBottom variant="h5" sx={{margin: '10px'}}>
                                {decode(post.title)}
                            </Typography>
                            <ExpandMore
                                expand={expanded}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon/>
                            </ExpandMore>
                        </CardActions>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Typography variant='subtitle2' style={{alignItems: 'center'}}>
                                    Used engine:
                                </Typography>
                                <Typography fontWeight="bold"> {post.usedEngine}</Typography>

                                <br/><Divider/><br/>
                                <Typography variant='subtitle2' style={{alignItems: 'center'}}>
                                    Prompt:
                                </Typography>
                                <Typography fontWeight="bold"
                                            fontStyle={'italic'}>"{decode(post.prompts || "User didn't share the prompt.")}"</Typography>
                            </CardContent>
                        </Collapse>
                        <CardContent>
                            <>
                                <CategoryButton category={decode(post.category)}/>
                                {post.tags && post.tags.map((tag, index) => (
                                    <Tag key={index} tag={tag}></Tag>
                                ))}
                                <span style={{float: 'right', display: 'flex', alignItems: 'center'}}>
                                <LikeButton isLiked={likeStatus.isLiked} onClick={handleLike} count={likeCount}
                                            disabled={likeStatus.isDisliked}/>
                                <DislikeButton isDisliked={likeStatus.isDisliked} onClick={handleDislike}
                                               count={dislikeCount} disabled={likeStatus.isLiked}/>
                                <CommentButton postId={post.id} onClick={handleCommentClick}
                                               incrementCommentCount={incrementCommentCount}/>
                                <ShareButtonWithDialog shareUrl={`https://cursed-ai.com/post/${post.id}`}/>
                            </span>
                            </>
                        </CardContent>
                        <Collapse in={commentSectionOpen} timeout="auto" unmountOnExit>
                            <CardContent>
                                <CommentSection postId={post.id} isOpen={commentSectionOpen}
                                                incrementCommentCount={incrementCommentCount}/>
                            </CardContent>
                        </Collapse>
                    </Card>
                    {showPopup && <ReportPopup onClose={handlePopupClose} onSubmit={handleSubmit} postId={post.id}/>}
                </div>
            </ThemeProvider>
        </>
    );
};

export default PostCard;
