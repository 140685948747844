import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import App from "./App";
import { FeedType } from "./FeedType";
import axios from "axios";
import { ClerkProvider, useUser, useAuth } from "@clerk/clerk-react";
import { dark } from "@clerk/themes";
import About from "./components/About/About";

const AppRoutes: React.FC = () => {

    const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
    if (!PUBLISHABLE_KEY) {
        throw new Error("Missing Publishable Key");
    }

    return (
        <ClerkProvider
            publishableKey={PUBLISHABLE_KEY}
            appearance={{ baseTheme: dark }}
            afterSignInUrl="/logged"
            afterSignUpUrl="/logged"
        >
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App feedType={FeedType.Top} />} />
                    <Route path="/category/:category" element={<App feedType={FeedType.Category} />} />
                    <Route path="/tag/:tag" element={<App feedType={FeedType.Tag} />} />
                    <Route path="/fresh" element={<App feedType={FeedType.Fresh} />} />
                    <Route path="/posts/search" element={<App feedType={FeedType.SearchResult} />} />
                    <Route path="/post/:id" element={<App feedType={FeedType.Post} />} />
                    <Route path="/user/profile/:userId" element={<App feedType={FeedType.User} />} />
                    <Route path="/logged/" element={<Logged />} />
                    <Route path="/about/" element={<About />} />
                </Routes>
            </BrowserRouter>
        </ClerkProvider>
    );
};

const Logged: React.FC = () => {
    const navigate = useNavigate();
    const { isSignedIn, user } = useUser();
    const { userId } = useAuth();

    useEffect(() => {
        if (isSignedIn) {
            axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                // useUser from clerk to send the user data
                email: user?.primaryEmailAddress?.emailAddress,
                fullName: user?.fullName || user?.username,
                avatarUrl: user?.hasImage ? user.imageUrl : null,
                clerkUserId: userId
            }).catch(error => {
                console.error('Login failed to backend', error); // but anyway, we need to handle the error
            });

            navigate('/');
        }
    }, [isSignedIn, user, navigate]);

    return null;
};

export default AppRoutes;
