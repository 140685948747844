import React from 'react';
import styles from './LikeButton.module.css';
import { IconButton } from "@mui/material";
import { ThumbUp } from "@mui/icons-material";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";

interface LikeButtonProps {
    isLiked: boolean;
    onClick: () => void;
    count: number;
    disabled: boolean;
}

const LikeButton: React.FC<LikeButtonProps> = ({ isLiked, onClick, count, disabled }) => (
    <div className={styles.iconContainer}>
        <IconButton aria-label={"like this post"} onClick={onClick} disabled={disabled} className={styles.iconButton}>
            {isLiked ? <ThumbUp className={styles.icon} /> : <ThumbUpAltOutlinedIcon className={styles.icon} />}
            <span className={styles.buttonCount}>{count}</span>
        </IconButton>
    </div>
);

export default LikeButton;
