import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { FeedType } from "./FeedType";

interface AppContextType {
    feedType: string;
    setFeedType: React.Dispatch<React.SetStateAction<FeedType>>;
    rateLimitError: RateLimitError | null;
    setRateLimitError: React.Dispatch<React.SetStateAction<RateLimitError | null>>;
}

interface RateLimitError {
    message: string;
    retryAfter: number;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppProvider = ({ children }: { children: ReactNode }) => {
    const [feedType, setFeedType] = useState<FeedType>(FeedType.Top);
    const [rateLimitError, setRateLimitError] = useState<RateLimitError | null>(() => {
        const storedError = localStorage.getItem('rateLimitError');
        return storedError ? JSON.parse(storedError) : null;
    });

    useEffect(() => {
        if (rateLimitError) {
            localStorage.setItem('rateLimitError', JSON.stringify(rateLimitError));
        } else {
            localStorage.removeItem('rateLimitError');
        }
    }, [rateLimitError]);

    const value = { feedType, setFeedType, rateLimitError, setRateLimitError };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
