import React, { useState } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import {
    FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton,
    FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon
} from 'react-share';
import styles from './ShareButtonWithDialog.module.css'; // Import the CSS module

interface ShareButtonProps {
    shareUrl: string;
}

const ShareButtonWithDialog = (props: ShareButtonProps) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={styles.iconContainer}>
            <IconButton aria-label="share" onClick={handleClickOpen} className={styles.iconButton}>
                <ShareIcon className={styles.icon} />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Share this post</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: '10px' }}>
                        <FacebookShareButton url={props.shareUrl}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton url={props.shareUrl}>
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <LinkedinShareButton url={props.shareUrl}>
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        <WhatsappShareButton url={props.shareUrl}>
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <EmailShareButton url={props.shareUrl}>
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ShareButtonWithDialog;
